import React, { useState, useEffect } from "react";
import axios from "axios";
import { uri } from "../../../../help/Api";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";
import { sto } from "../../../../Context/InshData";
import { useBody,useHeader } from "../../invCompany/style";

function HeaderD({found}) {

  // const clasH = useInvHeadrAF();
  const classHeader = useHeader();
  const [info, setInfo] = useState({});
  const getInfo = async () => {
    const tb = {
      GetFoundation: true,
      parms: { iFoundid: 1, branch: sto.branchi },
      autos: {},
    };
    axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setInfo(res.data.Found);
      })
      .catch((error) => {});
  };
  const styf = { fontWeight: "500" };
  const styfC = { fontWeight: "500", color: "#9a3f47" };
  useEffect(() => {
    getInfo();
  }, []);
  const clasm = useInvMain();
  const clas = useInvAF();
  const calssBody = useBody();
  return (
    <Grid
      item
      container
      className={classHeader.headerContainer}
      style={{ padding: 5, fontWeight: "bold" 
       
      }}
    >
      <Grid       item
      xs={12}
      // className={clas.Hedroot}
      className={calssBody.BdymtHeaderTopp} >
        <Grid item container >
      <Grid item xs={5} className={clasm.minleft}>
        <div style={styf}>{info.Disc1_header_en}</div>
        <div style={styf}>{info.Disc2_header_en}</div>
        <div style={styfC}>{info.Disc3_header_en}</div>{" "}
        <div style={styfC}>{info.Disc4_header_en}</div>
      </Grid>

      <Grid item xs={2} className={clasm.mincntr}>
        <div>
          <ButtonBase>
            {" "}
            <img
              src={`data:image/jpeg;base64,${info.Logo}`}
              className={clas.Hedlogo}
              alt={info.nameA}
            />{" "}
          </ButtonBase>
        </div>
      </Grid>

      <Grid item xs={5} className={clasm.minrit}>
        <div style={styf}>{info.Disc1_header_ar}</div>{" "}
        <div style={styf}>{info.Disc2_header_ar}</div>
        <div style={styfC}>{info.Disc3_header_ar}</div>{" "}
        <div style={styfC}>{info.Disc4_header_ar}</div>
      </Grid>
      </Grid>
      </Grid>
    </Grid>
  );
}
export default HeaderD;
