import { useState } from "react";
import {get} from 'lodash'
import toast from "react-hot-toast";

export default function useFormDataCRUD(
    submitFunction,
    updateFunction,
    removeFunction,
    key,
    _baseValues ={},
    onShowFunction,
    onEndFunction,
    defaultType= 'new',
){
    const [baseValues, setBaseValues] = useState(_baseValues)
    const [submitting, setSubmitting] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [object, setObject] = useState(_baseValues)
    const [formType, setFormType] = useState(defaultType)

    const onChange = (e, {name, value})=>{
        setObject({...object, [name]:value})
    }

    async function submit(value){
        const data = {...baseValues, ...value}
        setSubmitting(true)
        try{
            const submitted = await submitFunction(data)
            console.log(data);
            setSubmitting(false)
            toast.success('تم الإضافة')
            onEnd()
            return submitted
        } catch (e){
            toast.error('خطاء في عملية الإضافة');
            console.log(e);
        }
        setSubmitting(false)

    }

    async function update(value){
        setSubmitting(true)
        try{

            const updated = await updateFunction(value)
            setSubmitting(false)
            toast.success('تم التعديل')
            onEnd()
            return updated
        } catch (e){
            toast.error("لم يتم التعديل")
            console.log(e);
        }
        setSubmitting(false)
    }

    async function remove(id){
        setSubmitting(true)
        try{
            const removed = await removeFunction(id)
            console.log(removed.data);
            if(removed.data){
               toast.success('تم الحذف.. ')
                onEnd()
            } else {
                toast.error("لا يمكن الحذف لأن العنصر مرتبط بجداول اخرى")
            }
            setSubmitting(false)
            return removed
        } catch (e){
            console.log(e);
            toast.error(e)
        }
        setSubmitting(false)
        
    }
    
    function onShow(newObject ={}, type = 'new'){
        // if(get(newObject, 'constructor.name')=== 'Class') newObject ={}
        setObject(newObject)
        setFormType(type)
        onShowFunction && onShowFunction()
        
    }

    function onEnd(){
        setObject(_baseValues)
        setFormType('new');
        onEndFunction && onEndFunction()

    }

    return {
        onChange,
        submit,
        update, 
        remove,
        submitting,
        errorMessage,
        onShow,
        onEnd,
        object,
        formType,
    }
}