import React, { useState, useEffect } from "react";
import { Rept, sto } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
import axios from "axios";
import { uri } from "../../../../help/Api";
import { useHeader, useBody } from "../../../Invs/bill-template02/style";
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";

function HeaderJard({ found }) {

  const cls = useRptAF();
  const styf = { fontWeight: "1200" };
  const stysub = { fontWeight: "bold" };
  const styfC = { fontWeight: "500" };
 
  const calssBody = useBody();
  const classHeader = useHeader();
  const clasm = useInvMain();
  const clas = useInvAF();
  const iInfo = JSON.parse(localStorage.getItem('iInfos')) || {};
  const [state, setState] = useState({});
  const getInfo = async () => {
  axios.get(uri+`Foundation?branch=${sto.branchi}`).then( res => { setState(res.data); }).catch(error => { });
}
const [info, setInfo] = useState({});
const getInfo2 = async () => {
    const tb = {
      GetFoundation: true,
      parms: { iFoundid: 1, branch: sto.branchi },
      autos: {},
    };
    await axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setInfo(res.data.Found);
        console.log("the res is ", res.data);
      })
      .catch((error) => { });
  };

useEffect(() => {  
  getInfo();
  getInfo2();
}, []);

  return (
    <Grid item container className={cls.root}>
      <Grid
        item
        container
        className={classHeader.headerContainer}
        style={{ padding: 5, fontWeight: "bold" , border: "solid 1px", borderRadius: "5px",fontFamily: "Amiri-Regular",marginLeft: "0cm"}}
      >
         <Grid item xs={5} className={classHeader.leftHeaderen}>
          <div style={styf}>{info.Disc1_header_en}</div>
          <div style={stysub}>{info.Disc2_header_en}</div>
          <div style={styfC}>{info.Disc3_header_en}</div>{" "}
        </Grid>
        

        <Grid item xs={2} className={clasm.mincntr}>
          <div>
            <ButtonBase>
              {" "}
              <img
                src={`data:image/jpeg;base64,${info.Logo}`}
                className={clas.Hedlogo}
                alt={info.nameA}
              />{" "}
            </ButtonBase>
          </div>
        </Grid>

       
        <Grid item xs={5} className={classHeader.rightHeaderar}>
          <div style={styf}>{info.Disc1_header_ar}</div>{" "}
          <div style={stysub}>{info.Disc2_header_ar}</div>
          <div style={styfC}>{info.Disc3_header_ar}</div>{" "}
        </Grid>
      </Grid>
      <Grid item xs={12} className={[cls.title, cls.titleM]}>
        <div style={styf}>
        {iInfo.title}
        </div>
      </Grid>

      <Grid item container className={[cls.title, cls.titleS]}>
      <Grid item xs={12} container>
          <Grid item xs={6} style={{ textAlign: "right" }}>
          {'المخزن'} /{" "} {Rept.auto.safe}
          </Grid>
          <Grid item xs={3}>
           <div  style={styfC}>  {"المستخدم"}  {"   /"} {Rept.auto.user}</div> 
          </Grid>
          <Grid item xs={3}>         
            <div  style={styfC}> {"تاريخ الطباعة"} {"    /"} {Rept.nDate}</div> 
          </Grid>
        </Grid>
       
      </Grid>
    </Grid>
  );
}
export default HeaderJard;
