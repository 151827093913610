import { makeStyles } from "@material-ui/core/styles";

export const useBody = makeStyles((theme) => ({
  minclr: {
    fontWeight: "bold",
    fontFamily: "Amiri-Regular",
    textAlign: "center",
  },
  txt: {
    fontFamily: "Amiri-Regular",
  },

  BdymtTabl: {
    border: "solid 0.1px",
    borderRadius: "6px",
    padding: 1,
    marginTop:10,
    minHeight: 350,
  },
  BdymtHeader: {
    // borderTop: "solid 0.1px",
    // borderRadius: "6px",
    padding: 1,
    marginRight: 24,
    paddingLeft:24,
    minHeight: 150,
  },
  BdymtHeaderTopp: {
    // borderTop: "solid 0.1px",
    // borderRadius: "6px",
    border: "solid 1px",
    borderRadius: "7px",
    fontWeight: "500",
    padding:6,
    marginRight: 20,
    marginLeft:20,
    marginTop:12,
  
  },

  BdymtHeader1: {
    // borderTop: "solid 0.1px",
    // borderRadius: "6px",
    padding: 1,
    // marginRight: 22,
    paddingLeft:22,
    minHeight: 200,
  },
  BdymtHeader2: {
    // borderTop: "solid 0.1px",
    // borderRadius: "6px",
    padding: 1,
    paddingRight: 22,
    // marginLeft:22,
    minHeight: 200,
   
  },
  BdymtHeader21: {
    // borderTop: "solid 0.1px",
    // borderRadius: "6px",
    padding: 1,
    paddingRight: 22,
    // marginLeft:22,
    minHeight: 200,
    marginTop:10,
   
  },
  BdymtHeader212: {
    // borderTop: "solid 0.1px",
    // borderRadius: "6px",
    padding: 1,
    paddingLeft: 22,
    // marginLeft:22,
    minHeight: 200,
   marginTop:10,
  },
  BdymtHeader5: {
    // borderTop: "solid 0.1px",
    // borderRadius: "6px",
    padding: 1,
    paddingRight: 22,
    marginTop:12,
    // marginLeft:22,
  },
  BdymtHeader6: {
    // borderTop: "solid 0.1px",
    // borderRadius: "6px",
    padding: 1,
    paddingLeft: 20,
    marginTop:10,

   
    // marginLeft:22,
  },
  BdymtHeader3: {
    border: "solid 0.1px",
    // borderRadius: "6px",
    backgroundColor: "#f5ebdb ",
    padding: 5,
    // marginLeft:22,
  },
  BdytotTitle: {
    border: "solid 0.1px black",
    borderRadius: "6px",
    textAlign: "center",
    color: "#be151f",
    fontWeight: "bold",
    margin: 2,
  },
  totalItem: {
    padding: 2,
    border: "solid 0px",

    fontFamily: "Amiri-Regular",
  },
  footerContainer: {
    width: "19.4cm",
    marginLeft: "0.6cm",
  },
}));

export const useHeader = makeStyles((theme) => ({
  /* S Head */
  headerContainer: {
    width: "20.4cm",
    marginLeft: "0.3cm",
  },
  minclr: {
    color: "#7f0101",
    fontWeight: "bold",
    fontFamily: "Amiri-Regular",
    boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%)",
  },
  minroot: {
    // flexGrow: 1,
    // display:'flex',
    marginTop: 10,
    border: "solid 1px",
    borderRadius: "10px",
    padding: "5px",

    // zIndex: 1,
  },
  rightHeader: {
    textAlign: "center",
    whiteSpace: "normal",
    fontFamily: "Amiri-Regular",
    fontWeight: "900",
  },
  rightHeaderar: {
    textAlign: "right",
    whiteSpace: "normal",
    fontFamily: "Amiri-Regular",
    fontWeight: "900",
    paddingRight:"0.2cm"
  },
  leftHeaderen: {
    textAlign: "left",
    whiteSpace: "normal",
    fontFamily: "Amiri-Regular",
    fontWeight: "900",
    paddingLeft:"0.2cm"
   
  },
}));
