import React, { useState, useEffect } from "react";

import axios from "axios";
import { uri } from "../../../../help/Api";

import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import { sto } from "../../../../Context/InshData";
//import {useStylesHed} from '../../../St/comps/UseStyle';
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";
function FooterD() {
  //const clashd = useStylesHed();
  //  const iInfo = JSON.parse(localStorage.getItem('iInfos')) || {};
  //  const locRem = JSON.parse(localStorage.getItem('DataRem')) || {};
  //  const locRem = JSON.parse(localStorage.getItem('DataRem')) || {};
  const locDinv = JSON.parse(localStorage.getItem("datainv")) || {};
  const clas = useInvAF();
  const [found, setFound] = useState({});
  const [inv, setInv] = useState({});

  //  const inshInfoInv = {
  //   tot:'الاجمالي', totBefurDisc:'الإجمالي قبل الخصم',
  //   totDisc:'اجمالي الخصم', totAfterDisc:'الإجمالي بعد الخصم',
  //   totBefurAmount:'المجموع قبل الضريبة',totAmount:'إجمالي الضريبة',
  //   tax:'ضريبة القيمة المضافة',shaml:'الإجمالي يشمل القيمة المضافة',
  //   cash:'المدفوع',agel:'المتبقي',
  //   note:'البيان', tell:'رقم الهاتف',phone:'الجوال',
  //  }

  const getFound = async () => {
    axios
      .get(uri + `Foundation?branch=${sto.branchi}`)
      .then((res) => {
        console.log("the foundations is footer", res.data);
        setFound(res.data);
      })
      .catch((error) => { });
  };

  const getInv = async () => {
    axios
      .get(uri + `InvForFatora?proc_id=${locDinv.proc_id}`)
      .then((res) => {
        setInv(res.data);
      })
      .catch((error) => { });
  };

  useEffect(() => {
    getFound();
    getInv();
  }, []);

  const sty = {
    cntr: { textAlign: "center", paddingTop: 10 },
    lft: { textAlign: "left", padingLeft: 5 },
    rit: { textAlign: "right", padingRight: 5 },
    dirR: {
      direction: "rtl",
      color: "black",
      // position: "relative",
      // style={{ }}
      // position: "fixed",
      // bottom: 0,
      // height:"200px",
      // width: "100%",
    },
    dirL: { direction: "ltr" },
  };
  return (
    <Grid item container style={sty.dirR}>
      <Grid item container style={sty.cntr} spacing={0}>
        {/* <Grid item xs={6} style={{ fontFamily: "Amiri-Regular" }}>
          {inv.notes}
          <br />
          {found.BillNotes}
          <br />
          {"_____"}
          <br />

          {sto.usernames}
        </Grid> */}
        {/* <br />
        {inv.tax > 0 && (
          <Grid item xs={6}>
            <ButtonBase style={{ border: "solid 1px" }}>
              <img
                src={`data:image/jpeg;base64,${inv.qr_image}`}
                style={{ height: 100, width: 100 }}
                alt={found.nameA}
              />
            </ButtonBase>
          </Grid>
        )} */}

      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={1}
        style={{ paddingTop: 20, fontWeight: "bold", textAlign: "center" }}
      >
        <Grid item xs={12} style={{ fontFamily: "Amiri-Regular" }}>
        <Grid item xs={12} container className={clas.footerA}>
          
          <Grid item xs={6}  className={clas.BdytotCntMmm}>
          {inv.notes}
          </Grid>
          <Grid item xs={6} className={clas.BdytotCntMmm}>
          {found.BillNotes}
          </Grid>
          </Grid>
          <Grid item xs={12} container className={clas.footerB}>
          <br/>
          <Grid item xs={2}style={{ fontFamily: "Amiri-Regular" }}>
          {sto.usernames}
          </Grid>
          <Grid item xs={2}>
          {locDinv?.salesman}
        </Grid>
          <Grid item xs={4} style={{ fontFamily: "Amiri-Regular" }}>
          {" "}
          {"  البائع" + "     " + " Salesmans Sign "}
        </Grid>
        <Grid item xs={4} style={{ fontFamily: "Amiri-Regular" }}>
          {"توقيع المستلم" + "     " + " Receivrs Sign "}
        </Grid>
        </Grid>
        </Grid>

      </Grid>
    </Grid>
  );
}
export default FooterD;

