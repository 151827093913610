import React, { useState, useEffect } from "react";
import { txtA, txtE } from "../../../../Label/Lbl";
import BackdropScI from "../../../../services/BackdropScI";
import axios from "axios";
import { uri } from "../../../../help/Api";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";
import Paper from "@material-ui/core/Paper";
import { useBody } from "../style";
import { getTotalAmount } from "../../../../utils/utils";

const UnderHeader = () => {

  const clasm = useInvMain();
  const clas = useInvAF();

  const locInv = JSON.parse(localStorage.getItem("datainv")) || {};

  const [sta, setSta] = useState({ info: [], tot: {} });
  const [showSc, setShowSc] = useState(true);
  const calssBody = useBody();
  const getInfo = async () => {
    // alert('getInfo')
    const tb = {
      Rpt_Inv: true,
      wait: true,
      parms: {
        lprocid: locInv.proc_id,
        ibranchid: locInv.branch,
        iFoundid: 1,
        sProcTypeInvProc: locInv.ProcTypeInvProc,
      },
      autos: {},
    };

    await axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setSta({ info: res.data.RptInv, tot: res.data.infoBill });
        console.log("res.data.RptInv");
        console.log(res.data);
        setShowSc(res.data.wait.state);
      })
      .catch((error) => {
        console.log("catch");
      });
  };

  const styf = { fontWeight: "500" };
  const styfC = { fontWeight: "500", color: "#9a3f47" };
  useEffect(() => {
    // alert('getInfo')

    getInfo();
  }, []);


  return showSc ? (
    <BackdropScI />
  ) : (
    <Grid
      item
      container
      className={clas.Bdyroot}
      style={{
        direction: "rtl",
        // position: "relative",
        // top: "28mm",
        // // height: "360px",
        // width: "100%",
        // pageBreakAfter: "always",
      }}
    >
      <Grid item xs={12}>
    

        <Grid item container style={{ direction: "rtl" }}>
          {/* <Grid item xs={1}  className={clas.Bdyroot}></Grid> */}
          <Grid item xs={6} className={calssBody.BdymtHeader21}
         
          >
            <Grid item container className={clasm.mincntr}>
            <Grid item xs={12} className={calssBody.BdymtHeader3}>
                {"Customer"}
              </Grid>
              <Grid item xs={2} className={clas.BdyborBtR}>
                {txtA.custmName}
              </Grid>
              <Grid item xs={8} className={clasm.minborB}>
                <Paper className={clasm.minclr} style={{maxHeight:"1rem",minHeight:"1rem"}}>{sta.tot.cust_name}</Paper>
              </Grid>
              <Grid item xs={2} className={clas.BdyborBtL}>
                {"Name"}
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtR}>
                {"رقم المبنى"}

              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot.tax_no_build_cust}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
              {"Build No"}
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtR}>
              {"اسم الشارع"}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>
                <Paper className={clasm.minclr}style={{maxHeight:"1rem",minHeight:"1rem"}}>{sta.tot.tax_name_street_cust}</Paper>

                  {/* {moment(sta.tot.date).format("YYYY/MM/DD")} */}
                </Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {"Street Name"}
              </Grid>

              <Grid item xs={3} className={clas.BdyborBtR}>
              {"الحي"}

              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot.tax_name_district_cust}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {"District"}
              </Grid>

              <Grid item xs={3} className={clas.BdyborBtR}>
              {"المدينة"}

              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot.tax_name_city_cust}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {"City"}

              </Grid>

              <Grid item xs={3} className={clas.BdyborBtR}>
              {"البلد"}

              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot.tax_name_country_cust}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {"Country"}
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtR}>
              {"الرمز البريدي"}

              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot?.tax_zip_code_cust}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {"Postal Code"}

              </Grid>

              <Grid item xs={3} className={clas.BdyborBtR}>
              {"الرقم للعنوان"}

              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot?.tax_vat_registration_no_cust}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {"Additional No"}
              </Grid>

              <Grid item xs={3} className={clas.BdyborBtR}>
                {txtA.taxNumr}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot.tax_no_cust}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {txtE.taxNumr}
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtR}>
                {"Other No"}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot?.com_register}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {"Other No"}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} className={calssBody.BdymtHeader212}
         
         >
           <Grid item container className={clasm.mincntr}>
           <Grid item xs={12}  className={calssBody.BdymtHeader3}>
               {"Supplier"}
             </Grid>
             <Grid item xs={2} className={clas.BdyborBtR}>
               {txtA.cusNM}

             </Grid>
             <Grid item xs={8} className={clasm.minborB}>
               <Paper className={clasm.minclr} style={{maxHeight:"1rem",minHeight:"1rem"}}>{sta.tot.foundaion_name}</Paper>
             </Grid>
             <Grid item xs={2} className={clas.BdyborBtL}>
               {"Name"}
             </Grid>
             <Grid item xs={3} className={clas.BdyborBtR}>
               {"رقم المبنى"}

             </Grid>
             <Grid item xs={6} className={clasm.minborB}>
               <Paper className={clasm.minclr}>{sta.tot.tax_no_build_found}</Paper>
             </Grid>
             <Grid item xs={3} className={clas.BdyborBtL}>
             {"Build No"}
             </Grid>
             <Grid item xs={3} className={clas.BdyborBtR}>
             {"اسم الشارع"}
             </Grid>
             <Grid item xs={6} className={clasm.minborB}>
               <Paper className={clasm.minclr}style={{maxHeight:"1rem",minHeight:"1rem"}}>{sta.tot.tax_name_street_found}</Paper>
             </Grid>
             <Grid item xs={3} className={clas.BdyborBtL}>
               {"Street Name"}
             </Grid>

             <Grid item xs={3} className={clas.BdyborBtR}>
             {"الحي"}

             </Grid>
             <Grid item xs={6} className={clasm.minborB}>
               <Paper className={clasm.minclr}>{sta.tot.tax_name_district_found}</Paper>
             </Grid>
             <Grid item xs={3} className={clas.BdyborBtL}>
               {"District"}
             </Grid>

             <Grid item xs={3} className={clas.BdyborBtR}>
             {"المدينة"}

             </Grid>
             <Grid item xs={6} className={clasm.minborB}>
               <Paper className={clasm.minclr}>{sta.tot.tax_name_city_found}</Paper>
             </Grid>
             <Grid item xs={3} className={clas.BdyborBtL}>
               {"City"}

             </Grid>

             <Grid item xs={3} className={clas.BdyborBtR}>
             {"البلد"}

             </Grid>
             <Grid item xs={6} className={clasm.minborB}>
               <Paper className={clasm.minclr}>{sta.tot.tax_name_country_found}</Paper>
             </Grid>
             <Grid item xs={3} className={clas.BdyborBtL}>
               {"Country"}
             </Grid>
             <Grid item xs={3} className={clas.BdyborBtR}>
             {"الرمز البريدي"}

             </Grid>
             <Grid item xs={6} className={clasm.minborB}>
               <Paper className={clasm.minclr}>{sta.tot.tax_zip_code_found}</Paper>
             </Grid>
             <Grid item xs={3} className={clas.BdyborBtL}>
               {"Postal Code"}

             </Grid>

             <Grid item xs={3} className={clas.BdyborBtR}>
             {"الرقم للعنوان"}

             </Grid>
             <Grid item xs={6} className={clasm.minborB}>
               <Paper className={clasm.minclr}>{sta.tot.tax_additional_address_no_found}</Paper>
             </Grid>
             <Grid item xs={3} className={clas.BdyborBtL}>
               {"Additional No"}
             </Grid>

             <Grid item xs={3} className={clas.BdyborBtR}>
               {txtA.taxNumr}
             </Grid>
             <Grid item xs={6} className={clasm.minborB}>
               <Paper className={clasm.minclr}>{sta.tot.tax_vat_registration_no_found}</Paper>
             </Grid>
             <Grid item xs={3} className={clas.BdyborBtL}>
               {txtE.taxNumr}
             </Grid>
             <Grid item xs={3} className={clas.BdyborBtR}>
               {"Other No"}
             </Grid>
             <Grid item xs={6} className={clasm.minborB}>
               <Paper className={clasm.minclr}>{sta.tot?.com_register}</Paper>
             </Grid>
             <Grid item xs={3} className={clas.BdyborBtL}>
               {"Other No"}
             </Grid>
           </Grid>
         </Grid>
          {/* <Grid item xs={1}></Grid> */}

          {/* <Grid item xs={6} className={calssBody.BdymtHeader1} 

           >
            <Grid item container className={clasm.mincntr}>
              <Grid item xs={3} className={clas.sBdyborBtR}>
                {txtE.invNumr}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot.id}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {txtA.invNumr}
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtR}>
                {txtE.invRef}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot.refno}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {txtA.invRef}
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtR}>
                {txtE.invDate}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>
                  {moment(sta.tot.date).format("YYYY/MM/DD")}
                </Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {txtA.invDate}
              </Grid>

              <Grid item xs={3} className={clas.BdyborBtR}>
                {txtE.custmName}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot.cust_name}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {txtA.custmName}
              </Grid>

              <Grid item xs={3} className={clas.BdyborBtR}>
                {txtE.custAddress}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot.cust_address}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {txtA.custAddress}
              </Grid>

              <Grid item xs={3} className={clas.BdyborBtR}>
                {txtE.taxNumr}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot.tax_no_cust}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {txtA.taxNumr}
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtR}>
                {"Com.Register"}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot?.com_register}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {"السجل التجاري"}
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>

    </Grid>
  );
};

export default UnderHeader;
