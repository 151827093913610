import React, { useEffect } from "react";
import { Rept } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
import MaterialTable from "material-table";
import { stElemnt } from "../../../../St/Reviews/cStyle";

import { colors, Container, makeStyles, Typography } from "@material-ui/core";

export function Totals({ data }) {
  const cls = useRptAF();
  return (
    <Grid
    container
    lg={12}
    md={12}
    className={cls.rootBdydown}
    style={{ paddingBottom: 5, backgroundColor: colors.grey[100],fontFamily: "Amiri-Regular"  }}
  >
    <Grid container xs={12} /*className={cls.rootchip}*/  style={{ paddingBottom: 5, 
      backgroundColor: colors.grey[100],fontFamily: "Amiri-Regular",
      border: "solid 1px",
      padding: "0.3rem 0.1rem 0.3rem 0.1rem", 
      borderRadius: "0px 0px 5px 0px" }}>
           
           <Grid item xs={3} className={[cls.chipVMU]}>
            {"إجمالي عدد الأصناف"}
          </Grid>
      <Grid item xs={2} className={[cls.chipVMU]}>
      {Rept.tot.totval}
      </Grid>
        <Grid item xs={3} className={[cls.chipVMU]}>
      {"إجمالي التكلفة"}
      </Grid>
      <Grid item xs={2} className={[cls.chipVMU]}>
      {Rept.tot.totTaqlfa}
      </Grid>
    </Grid>
    {/* <Grid container xs={6} 
    style={{ paddingBottom: 5, backgroundColor: colors.grey[100],fontFamily: "Amiri-Regular"  ,
      border: "solid 1px",
      padding: "0.2rem 0.1rem 0.2rem 0.1rem", 
      borderRadius: "0px 0px 5px 0px",
      marginTop:"0.1px"
    }}>
      <Grid item xs={5} className={[cls.chipVMU]}>
      {"إجمالي التكلفة"}
      </Grid>
      <Grid item xs={2} className={[cls.chipVMU]}>
      {Rept.tot.totTaqlfa}
      </Grid>
      <Grid item xs={4} className={[cls.chipVMU]}></Grid>
 
    </Grid> */}
  
  </Grid>
  );
}
function BodyJard({ data, isFullPage, isLast }) {
  const cls = useRptAF();
 
  const cellS = {

    padding: "0px 0px",
    textAlign: "-webkit-center",
    margin: "0px",
    whiteSpace: "normal",
    border: "solid 0.1px",
    fontSize: "0.55rem",
    width: "320px",
    fontFamily: "Amiri-Regular",
    fontWeight:"bold",
  };
  var columns = [
    { title: "رقم الباركود", field: "barcode" },
    { title: "الصنف", field: "Name_Item",cellStyle:cellS },
    { title: "الرصيد", field: "Balance" },
    { title: "متوسط التكلفة", field: "cost" },
    { title: "إجمالي التكلفة", field: "sumCost_item" },
    { title: "إجمالي الوارد", field: "Income" },
    { title: "إجمالي المباع", field: "Outcome" },
  ];

//   const data2 = data.map((row) => {
//     return {
//       ...row,
//       balance: row.balance.toFixed(2),
//       cost: row.cost.toFixed(2),
//       sumCost_item: row.sumCost_item.toFixed(2),
//       Income: row.Income.toFixed(2),
//       Outcome: row.Outcome.toFixed(2),
//     };
//   });
  return (
    <Grid item container className={cls.rootBdy}>
      <Grid item container className={cls.rootBdyup}>
        <Grid
          item
          xs={12}
          style={{
            maxHeight: isFullPage ? isLast ?  "58rem": "64.3rem": "45.65rem",
            minHeight: isFullPage ? isLast ?  "58rem": "64.3rem": "45.65rem",
          }}
        >
          <MaterialTable
            style={{ boxShadow: "none" }}
            // style={{direction:'ltr'}}
            options={{
              showTitle: false,
              toolbar: false,
              sorting: false,
              selection: false,
              // ToolBar Search
              searchFieldAlignment: "left",
              searchFieldVariant: "outlined",
              // ToolBar header
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              pageSize: 5,
              paginationType: "stepped",
              showFirstLastPageButtons: false,

              headerStyle: {
                ...stElemnt.AF.header,
                backgroundColor: colors.grey[100],
                fontWeight: "bold",
                paddingTop: "2px",
                paddingBottom:"2px",
                border: "solid 0px",
                border: "solid 0.1px",
               
                
              },
              paging: false,
             // headerStyle: { ...stElemnt.AF.header, backgroundColor: "grey" },
              cellStyle: {
                ...stElemnt.AF.cell,
                padding: "0px 1px",
                // fontSize: 8,
              },
              // maxBodyHeight: isFullPage ? "56.3rem" : "35.6rem",
              // minBodyHeight: isFullPage ? "56.3rem" : "35.6rem",
            }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <h5 style={{ color: "GrayText" }}>
                    لا توجد ارصدة بعد في هذا الحساب
                  </h5>
                ),
              },
            }}
            columns={columns}
            data={data}
          />
          {isLast && <Totals data={data ?? []} />}
        </Grid>
        {/* <Grid item xs={12}>
        </Grid> */}
      </Grid>
  
      
    </Grid>
  );
}

export default BodyJard;
