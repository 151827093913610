import React, { useState, useEffect } from "react";
import { txtA, txtE } from "../../../../Label/Lbl";
import BackdropScI from "../../../../services/BackdropScI";
import axios from "axios";
import { uri } from "../../../../help/Api";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import MaterialTable, { MTableBody, MTableBodyRow } from "material-table";
// Ust
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";
// csG
import { stElemnt } from "../../../../St/Reviews/cStyle";
import Paper from "@material-ui/core/Paper";
import { getTotalAmount } from "../../../../utils/utils";
import { useBody } from "../../invCompany/style";

const InvTotal = () => {
    const clasm = useInvMain();
  const clas = useInvAF();
  const calssBody = useBody();
  const locInv = JSON.parse(localStorage.getItem("datainv")) || {};

  const [sta, setSta] = useState({ info: [], tot: {} });
  const [showSc, setShowSc] = useState(true);
  const styfC = { fontWeight: "1300" };
  const getInfo = async () => {
    // alert('getInfo')
    const tb = {
      Rpt_Inv: true,
      wait: true,
      parms: {
        lprocid: locInv.proc_id,
        ibranchid: locInv.branch,
        iFoundid: 1,
        sProcTypeInvProc: locInv.ProcTypeInvProc,
      },
      autos: {},
    };

    await axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setSta({ info: res.data.RptInv, tot: res.data.infoBill });
        console.log("res.data.RptInv");
        console.log(res.data);
        setShowSc(res.data.wait.state);
      })
      .catch((error) => {
        console.log("catch");
      });
  };

  useEffect(() => {
    // alert('getInfo')

    getInfo();
  }, []);
  return (
    <Grid
    item
    container
    // className={clas.BdytotSub}
    style={{fontFamily: "Amiri-Regular" }}
    // className={calssBody.BdymtHeaderTopp}
  >
    
    <Grid item xs={5} className={clas.BdyborBtRTOPTotal}>
      
      <div style={styfC}>   {txtA.amountWithVat}</div>{" "}
    </Grid>
    <Grid item xs={5}  className={clas.BdyborBtRTOPTotal}>
      {txtE.amountWithVat}
    </Grid>
    <Grid item xs={2} className={clas.BdyborBtRTOPTotal}>
      {getTotalAmount(sta.tot)}
    </Grid>

    <Grid item xs={5} className={clas.BdyborBtRTOPTotal2}>
     
      <div style={styfC}>   {txtA.discount}</div>{" "}
    </Grid>
    <Grid item xs={5} className={clas.BdyborBtRTOPTotal2}>
      {txtE.discount}
    </Grid>
    <Grid item xs={2} className={clas.BdyborBtRTOPTotal2}>
      {sta.tot.discount}
    </Grid>

    <Grid item xs={5} className={clas.BdyborBtRTOPTotal2}>
     
      <div style={styfC}>  {"الاجمالي بعد الخصم"}</div>{" "}
    </Grid>
    <Grid item xs={5} className={clas.BdyborBtRTOPTotal2}>
      {"Amount After Discount"}
    </Grid>
    <Grid item xs={2} className={clas.BdyborBtRTOPTotal2}>
      {getTotalAmount(sta.tot) - sta.tot?.discount}
    </Grid>

    <Grid item xs={5} className={clas.BdyborBtRTOPTotal2}>
     
      <div style={styfC}> {txtA.amountOfVat}</div>{" "}
    </Grid>
    <Grid item xs={5} className={clas.BdyborBtRTOPTotal2}>
      {txtE.amountOfVat}
    </Grid>
    <Grid item xs={2} className={clas.BdyborBtRTOPTotal2}>
      {sta.tot.amountOfVat}
    </Grid>

    <Grid item xs={5} className={clas.BdyborBtRTOPTotal2}>
      
      <div style={styfC}>{txtA.tot_net}</div>{" "}
    </Grid>
    <Grid item xs={5} className={clas.BdyborBtRTOPTotal2}>
      {txtE.tot_net}
    </Grid>
    <Grid item xs={2} className={clas.BdyborBtRTOPTotal2}>
      {sta.tot.tot_net}
    </Grid>
  </Grid>
  );
};

export default InvTotal;
