import React, { useState, useEffect } from "react";
import { txtA, txtE } from "../../../../Label/Lbl";
import BackdropScI from "../../../../services/BackdropScI";
import axios from "axios";
import { uri } from "../../../../help/Api";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";
import Paper from "@material-ui/core/Paper";
import { getTotalAmount } from "../../../../utils/utils";
import { useBody } from "../../invCompany/style";
import ButtonBase from "@material-ui/core/ButtonBase";
const UnderHeaderT = () => {

  const clasm = useInvMain();
  const clas = useInvAF();
  const [inv, setInv] = useState({});
  const locInv = JSON.parse(localStorage.getItem("datainv")) || {};

  const [sta, setSta] = useState({ info: [], tot: {} });
  const [showSc, setShowSc] = useState(true);
  const calssBody = useBody();
  const getInfo = async () => {
    // alert('getInfo')
    const tb = {
      Rpt_Inv: true,
      wait: true,
      parms: {
        lprocid: locInv.proc_id,
        ibranchid: locInv.branch,
        iFoundid: 1,
        sProcTypeInvProc: locInv.ProcTypeInvProc,
      },
      autos: {},
    };

    await axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setSta({ info: res.data.RptInv, tot: res.data.infoBill });
        console.log("res.data.RptInv");
        console.log(res.data);
        setShowSc(res.data.wait.state);
      })
      .catch((error) => {
        console.log("catch");
      });
  };
  const getInv = async () => {
    axios
      .get(uri + `InvForFatora?proc_id=${locInv.proc_id}`)
      .then((res) => {
        setInv(res.data);
      })
      .catch((error) => { });
  };

  const styf = { fontWeight: "500" };
  const styfC = { fontWeight: "500", color: "#9a3f47" };
  useEffect(() => {
    // alert('getInfo')

    getInv();
    getInfo();
  }, []);


  return showSc ? (
    <BackdropScI />
  ) : (
    <Grid
      item
      container
      className={clas.Bdyroot}
      style={{
        direction: "rtl",
        // position: "relative",
        // top: "28mm",
        // // height: "360px",
        // width: "100%",
        // pageBreakAfter: "always",
      }}
    >
      <Grid item xs={12}>

        <Grid item container >
          {/* <Grid item xs={1}></Grid> */}
          <Grid item container style={{ direction: "rtl" }}>
            {/* <Grid item xs={1}  className={clas.Bdyroot}></Grid> */}
            <Grid item xs={3} className={calssBody.BdymtHeader5}

            >
              <Grid item container className={clasm.mincntr}>
                <Grid item xs={12} >

                  
                  {inv.tax > 0 && (
                    <Grid item xs={12}>
                      <ButtonBase style={{ border: "solid 1px" }}>
                        <img
                          src={`data:image/jpeg;base64,${inv.qr_image}`}
                          style={{ height: 100, width: 100 }}
                        // alt={found.nameA}
                        />
                      </ButtonBase>
                    </Grid>
                  )}
                </Grid>

              </Grid>
            </Grid>
            <Grid item xs={9} className={calssBody.BdymtHeader6}

            >
              <Grid item container className={clasm.mincntr}>

                <Grid item xs={6} className={clas.BdytitleHedQ2}>
                  {sta.tot.tax_no_cust === ""
                    ? "فاتورة ضريبية مبسطة" + " " + "Simplified Tax Invoice"
                    : "فاتورة ضريبية" + " " + "Tax Invoice"}
                </Grid>
                <Grid item xs={6} className={clas.BdytitleHedQ2}>
                  {locInv.invN +
                    " " +
                    sta.tot.inv_type_name +
                    " " +
                    sta.tot.pay_type_name}
                </Grid>
                <Grid item xs={3} className={clas.BdyborBtRTOP1}>
                  {txtE.invNumr}
                </Grid>
                <Grid item xs={6} className={clas.BdyborBtRTOP3}>
                  <Paper className={clasm.minclr}>{sta.tot.id}</Paper>
                </Grid>
                <Grid item xs={3} className={clas.BdyborBtRTOP2}>
                  {txtA.invNumr}
                </Grid>

                <Grid item xs={3} className={clas.BdyborBtR}>
                  {txtE.invDate}
                </Grid>
                <Grid item xs={6} className={clasm.minborB}>
                  <Paper className={clasm.minclr}>
                    {moment(sta.tot.date).format("YYYY/MM/DD")}
                  </Paper>
                </Grid>
                <Grid item xs={3} className={clas.BdyborBtL}>
                  {txtA.invDate}
                </Grid>

              </Grid>
            </Grid>
            {/* <Grid item xs={1}></Grid> */}

            {/* <Grid item xs={6} className={calssBody.BdymtHeader1} 

           >
            <Grid item container className={clasm.mincntr}>
              <Grid item xs={3} className={clas.sBdyborBtR}>
                {txtE.invNumr}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot.id}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {txtA.invNumr}
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtR}>
                {txtE.invRef}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot.refno}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {txtA.invRef}
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtR}>
                {txtE.invDate}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>
                  {moment(sta.tot.date).format("YYYY/MM/DD")}
                </Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {txtA.invDate}
              </Grid>

              <Grid item xs={3} className={clas.BdyborBtR}>
                {txtE.custmName}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot.cust_name}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {txtA.custmName}
              </Grid>

              <Grid item xs={3} className={clas.BdyborBtR}>
                {txtE.custAddress}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot.cust_address}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {txtA.custAddress}
              </Grid>

              <Grid item xs={3} className={clas.BdyborBtR}>
                {txtE.taxNumr}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot.tax_no_cust}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {txtA.taxNumr}
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtR}>
                {"Com.Register"}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot?.com_register}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {"السجل التجاري"}
              </Grid>
            </Grid>
          </Grid> */}
          </Grid>
          {/* <Grid item xs={1}></Grid> */}
        </Grid>
      </Grid>

    </Grid>
  );
};

export default UnderHeaderT;
